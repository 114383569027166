import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Text, useThemeUI } from "theme-ui";
import Copy from "components/Copy";

const ContentRowCopy = ({ data, ...rest }) => {
  const { copy } = data;
  const { setColorMode } = useThemeUI();
  
  return (
    <Wrapper {...rest}>
      <Copy data={copy.json} />
    </Wrapper>
  );
};
export default ContentRowCopy;

const Wrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.text};

  width: 95%;
  ${({ theme }) => theme.mediaQueries.medium} {
    max-width: ${({ theme }) => theme.maxWidth.copy};
  }
`;
