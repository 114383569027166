import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import Link from "next/link";
import ArrowLink from "./ArrowLink";

const Copy = ({ data }) => {
  if (!data) return null;

  const renderOption = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <Text variant="copyHeading" as="h1">
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Text variant="copyHeading4">{children}</Text>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text as="p" variant="copyBody">
          {children}
        </Text>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        let {
          content,
          data: { uri: linkUrl },
        } = node;
        const { value: textValue } = content[0];
        const buttonRegExp = new RegExp("Button:(.*?)]", "g");

        const isButtonText = textValue.match(buttonRegExp);
        let buttonText;
        if (isButtonText) {
          const matchAllRegex = textValue.matchAll(buttonRegExp);
          for (const match of matchAllRegex) {
            buttonText = match[1];
          }
        }
        const isExternal = Boolean(
          linkUrl?.match(new RegExp("external:")) ||
            linkUrl?.match(new RegExp("^(http|https)://"))
        );
        if (isExternal) linkUrl = linkUrl?.split("external:")[1] || linkUrl;

        const [firstChild] = children;

        if (isButtonText && linkUrl) {
          return (
            <Link href={linkUrl} passHref>
              <a target={isExternal ? "_blank" : undefined}>
                <Button>{buttonText}</Button>
              </a>
            </Link>
          );
        } else if (firstChild?.type === "i") {
          const newNode = JSON.parse(JSON.stringify(node));
          newNode.data.uri = linkUrl;
          return <ArrowLink data={newNode} isExternal={isExternal} />;
        } else if (linkUrl?.indexOf("/") === 0) {
          return (
            <Link href={linkUrl} passHref>
              <a target={isExternal ? "_blank" : undefined}>{textValue}</a>
            </Link>
          );
        } else {
          return documentToReactComponents(node);
        }
      },
    },
    renderText: (text) =>
      text
        .replace(new RegExp(/\u2028/g, "g"), "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br key={`br-${i}`} />, text]),
  };

  return <Wrapper>{documentToReactComponents(data, renderOption)}</Wrapper>;
};
export default Copy;

const Wrapper = styled(Box)`
  display: block;
  p {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    font-family: "CentraNo1-Medium";
  }

  h1 {
    margin-bottom: 1.5rem;
  }

  li {
    padding: none;
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }
  hr + p {
    margin-bottom: 0.8em;
    margin-top: 0.8em;
  }
`;
