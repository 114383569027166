import styled from "@emotion/styled";
import { Field, useField } from "formik";
import React from "react";
import { Box } from "theme-ui";
import ErrorMessage from "./ErrorMessage";

const Input = ({ helperText, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Wrapper mb={["21px", "42px"]}>
      <StyledInput mb={[3, 4]} {...props} {...field} />
      {helperText && <HelperText mt={[1, 2]}>{helperText}</HelperText>}
      {meta?.error && meta?.touched && <ErrorMessage message={meta?.error} />}
    </Wrapper>
  );
};

export default Input;

const StyledInput = styled(Field)`
  background-color: transparent;
  font-family: inherit;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[8]};
  padding: 0.4rem 0;
  display: block;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[6]};
  }
  /* Change the white to any color */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${({ theme }) =>
    theme.mq({
      fontSize: ["16px", "21px"],
    })};
`;

const HelperText = styled(Box)`
  color: ${({ theme }) => theme.colors.grey[6]};
  line-height: 100%;
  font-size: 15px;
`;

const Wrapper = styled(Box)``;
