import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import Arrow from "components/Arrow";
import Link from "next/link";

const ArrowLink = ({ data, isExternal }) => {
  const href = data?.data?.uri;
  const textValue = data?.content[0]?.value;

  const isFile = Boolean(href?.match(new RegExp("^.*.(pdf|PDF|doc|DOC)$")));

  return (
    <Link href={href || "/"} passHref>
      <Wrapper
        as="a"
        target={isExternal ? "_blank" : undefined}
        dir={isFile ? "down" : "right"}
      >
        {textValue}
        <Arrow
          as="span"
          ml={[1, 2]}
          width={["10px", "14px"]}
          dir={isFile ? "down" : "right"}
        />
      </Wrapper>
    </Link>
  );
};
export default ArrowLink;

const Wrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.text};
  display: flex;

  svg {
    ${({ theme }) => theme.transitions.arrowShift};
  }

  &:hover {
    svg {
      transform: ${({ theme, dir }) =>
        dir === "right" ? `translateX(4px);` : `translateY(4px)`};
    }
  }

  text-decoration: none !important;
`;
