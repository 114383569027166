import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";

const ErrorMessage = ({ message }) => {
  return (
    <Wrapper mt={[1, 2]}>
      <Text variant="formErrorMessage">{message}</Text>
    </Wrapper>
  );
};
export default ErrorMessage;

const Wrapper = styled(Box)`
  * {
    color: #FF4D4D !important;
    font-size: 15px;
  }
`;
