import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Container as ContainerComponent,
  Text,
  ThemeProvider,
} from "theme-ui";
import { css } from "@emotion/react";

import light from "theme/light-theme";
import defaultTheme from "theme";

const ContentRowLayout = ({
  children,
  fullWidth,
  label,
  noBorder = false,
  theme,
  borderType = "under",
  ...rest
}) => {
  if (theme === "Light") noBorder = true;

  const GridWrapper = styled(Box)`
    display: flex;
    flex-direction: column-reverse;
    ${({ theme }) => theme.mediaQueries.medium} {
      display: grid;
      grid-template-columns: ${fullWidth ? "1fr" : "1fr 1fr"};
    }
  `;
  const Wrapper = styled(Box)`
    ${({ theme }) =>
      noBorder
        ? ``
        : css`
            border-top: 1px solid ${theme.colors.grey[4]};
          `};
  `;
  const gridWrapperBorder = borderType === "under" ? [3, "42px"] : [2, 3];
  const wrapperMarginTop = borderType === "over" ? [0] : [2, 3];

  return (
    <ThemeProvider theme={theme === "Light" ? light : defaultTheme}>
      <OutsideWrapper {...rest} hasTheme={theme}>
        <Container className="content-row-layout-container" px={[3, 4]}>
          <Wrapper pt={wrapperMarginTop} className="content-row-layout-wrapper">
            {label && (
              <RowLabelWrapper>
                <RowLabel variant="smallCap">{label}</RowLabel>
              </RowLabelWrapper>
            )}
            <GridWrapper
              className="content-row-layout-grid"
              pt={gridWrapperBorder}
              pb={["48px", "90px"]}
            >
              {children}
            </GridWrapper>
          </Wrapper>
        </Container>
      </OutsideWrapper>
    </ThemeProvider>
  );
};
export default ContentRowLayout;

const RowLabelWrapper = styled(Box)`
  width: 100%;

  ${({ theme }) =>
    theme.mq({
      height: ["24px", "32px"],
    })};
`;
const Container = styled(ContainerComponent)``;
const OutsideWrapper = styled(Box)`
  background-color: ${({ theme, hasTheme }) =>
    hasTheme ? theme.colors.rowBg : `transparent`};
`;
const RowLabel = styled(Text)``;
